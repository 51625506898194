// let env = process.env.VUE_APP_VERSION;
import Vue from 'vue'
import App from './App.vue'

import { loadRouter } from './router/index'
// import router from './router/router_froms'

import './request/loader'
import store from './store/index';

import iView from 'view-design';
import dateformat from 'dateformat'
import './v-viewer.js'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';



import 'iview/dist/styles/iview.css'
import './style/style.css'
import { Message } from 'iview'
import moment from 'moment';
import ElementUI from 'element-ui';
import locale from 'iview/dist/locale/en-US';
import lang from './utils/en_US';

Vue.config.productionTip = false

Vue.use(iView, {
    transfer: true,
    locale,
})
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput);
store.dispatch('loadToken');
Vue.use(ElementUI);
Vue.prototype.$Message = Message
Vue.prototype.$dateformat = dateformat
Vue.prototype.$moment = moment
Vue.prototype.$language = lang
// Vue.prototype.$api = api
// new Vue({
//   store,
//   router,
//   render: h => h(App),
// }).$mount('#app')
loadRouter()
    .then(router => {
        new Vue({
            store,
            router, // 使用动态导入的路由
            render: h => h(App),
        }).$mount('#app');
    })
    .catch(error => {
        console.error('Failed to load router:', error); // 捕获和打印错误
    });