import { get, post, checkImageLoad } from './index.js'
export default {
    getUserInfos: (data, config) => get('data/userInfo/getUserInfos', data, config),
    getCountryCode: data => get('data/userInfo/getCountryCode', data),
    deleteUserInfos: (data, config) => get('data/userInfo/deleteUserInfos', data, config),
    addUserInfo: data => post('data/userInfo/addUserInfo', data, {
        transformRequest: function (data) {
            return JSON.stringify(data)
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }),
    checkImageLoads: data => checkImageLoad(data),
    login: data => post('data/userInfo/login', data),
    setPwd: data => post('data/userInfo/setPwd', data)
}