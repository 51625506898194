<template >
    <div class="box">
        <Spin fix>
            <Icon type="ios-loading" size=100 class="demo-spin-icon-load"></Icon>
            <div style="font-size: 30px;">Loading</div>
        </Spin>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    
    name: 'ConfigPage',
    data() {
        return {
        }
    },
    computed: {
        ...mapState({ 
            token: state => state.token
        }),
    },
    created() {
        if (this.$route.query.token) {
            this.token = this.$route.query.token;
            this.$store.dispatch('saveToken', this.token);
        }
        this.load();
    },
    methods: {
        async load() {
            if(!this.token){
                this.$Message.error('请先登录');
                this.$router.replace({ name: 'login'});
            }else{
                this.$router.replace({ name: 'Home'})
            }
            
        }
      
    }
}
</script>
<style scoped>
    .box{
        width: 100%;
        height: 100%;
    }
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
</style>