<template>
    <div>
        <Drawer title="设置" :closable="false" width="640" v-model="setting">
            <Form label-position="top">
                <FormItem label="字段" v-if="type == 'table'">
                    <Checkbox-group v-model="tableColumnsChecked" @on-change="changeTableColumns">
                        <Row>
                            <Col span="8" v-for="column in columns" :key="column.key">
                                <span v-if="column.title && column.type!== 'index'">
                                    <Checkbox :label="column.key">{{column.title}}</Checkbox>
                                </span>
                                
                            </Col>
                        </Row>
                    </Checkbox-group>
                </FormItem>
            </Form>
        </Drawer>
        <header>
            <span class="back" @click="$router.go(-1)"> <Icon type="ios-arrow-back" size="16" />返回</span>
            <h1>消耗记录</h1>
        </header>
        <div class="main-content">
   
        <Collapse class="search">
            <Panel name="1">
                搜索
                <div class="searchButWrap">
                
                <Button
                    size="small"
                    icon="ios-search"
                    @click.stop="search()"
                    type="primary"
                    style="margin-right: 10px"
                    >搜索</Button
                >
                <Button 
                    v-if="admin"
                    type="small" 
                    icon="md-add"
                    @click="add" 
                    style="margin-right: 10px">新增</Button>
                <DelButton
                  v-if="admin"
                    :styleType="'del'"
                    style="margin-right: 10px"
                    :api="$api.api.del"
                    keyField="id"
                    icon="md-close" 
                    :ids="select"
                    @on-success="refresh">
                </DelButton>
                <Button size="small" @click.stop="filterReset()">清除</Button>
                </div>
                <template #content>
                <Form label-position="top" ref="formValidate" :model="filters">
                    <Row :gutter="16">
                    <Col span="6">
                        <FormItem label="日期（开始）">
                            <DatePicker style="width:100%" placeholder="请选择" type="date" v-model="filters.times1" format="yyyy-MM-dd"  />
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="日期（结束）">
                            <DatePicker style="width:100%" placeholder="请选择" type="date" v-model="filters.times2" format="yyyy-MM-dd"  />
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="广告渠道">
                            <Input v-model="filters.types" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="广告花费（$）">
                            <InputNumber
                            :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                             v-model="filters.amount" clearable
                             style="width:100%"></InputNumber>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="备注">
                            <Input v-model="filters.remark" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="CPM">
                            <Input v-model="filters.cpm" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="展示数">
                            <Input v-model="filters.zss" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="点击数">
                            <Input v-model="filters.djs" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="点击率">
                            <Input v-model="filters.djl" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="点击均价（$）">
                            <Input v-model="filters.djjj" clearable></Input>
                        </FormItem>
                    </Col>
                    <Col span="6" v-if="admin">
                        <FormItem label="用户">
                            <Select style="width:100%" v-model="filters.user_id" clearable filterable>
                                <Option v-for="item in UserList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    </Row>
                </Form>
                </template>
            </Panel>
        </Collapse>
        <div class="fund-box fund-box1">
            <Table 
            width="100%"
            :height="height"
            :columns="columnsList" 
            :data="data"
            v-bind="$attrs"
            v-on="$listeners"
            @on-selection-change="selectRow"
            @on-sort-change="sortChange"
            border
            stripe
            :loading="loading"
        
            ref="table"
            ></Table>
            <div slot="footer" class="footer">
                <Page
                    :total="total"
                    show-sizer
                    :current="page"
                    :page-size="rows"
                    :page-size-opts="[10, 15, 20, 50, 100, 500]"
                    @on-change="changePage"
                    @on-page-size-change="changePageSize"
                    style="float: left;"
                ></Page>
                <Divider type="vertical" />
                <p class="tiaozhuan">
                    <span>到</span
                    ><Input
                    v-model="yema"
                    style="width: 60px"
                    @on-enter="setpage"
                    @on-blur="setpage"
                    /><span>页</span>
                </p>
                <span
                    >共 <b>{{ total }}</b> 条</span
                >
                <div class="tool" style="float: right;">
                    <Tooltip content="刷新">
                        <Button type="text" @click.native="getListData()" size="small">
                            <Icon type="md-refresh" size="23" />
                        </Button>
                    </Tooltip>
                    <Divider type="vertical" v-if="isSetting" />
                    <Tooltip content="设置" placement="top" v-if="isSetting">
                        <Button type="text" @click="setting = true" size="small">
                            <Icon type="md-settings" size="20" />
                        </Button>
                    </Tooltip>
                </div>
                </div>
            </div>
        </div>
        <Modal  v-model="edModal" :title="title">
            <Form :model="formData" :rules="searchRuleValidate" ref="form">
                <Row :gutter="24">
                    <Col span="12" v-if="admin && title == '新增'">
                        <FormItem label="用户" prop="user_id">
                            <Select style="width:100%" v-model="formData.user_id" clearable filterable @on-change="selectOption">
                                <Option v-for="item in UserList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="日期" prop="dates">
                            <DatePicker v-model="formData.date" @on-change="setTime" style="width:100%" placeholder="请选择" type="date" format="yyyy-MM-dd"  />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="广告渠道" prop="types">
                            <Input placeholder="请输入广告渠道" clearable  v-model="formData.types" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="广告花费（$）" prop="amount">
                            <Input placeholder="请输入广告花费" clearable  v-model="formData.amount" type="number"/>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="CPM">
                            <Input placeholder="请输入CPM" clearable  v-model="formData.cpm" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="展示数">
                            <Input placeholder="请输入展示数" clearable  v-model="formData.zss" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="点击数">
                            <Input placeholder="请输入点击数" clearable  v-model="formData.djs" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="点击率">
                            <Input placeholder="请输入点击率" clearable  v-model="formData.djl" />
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="点击均价($)">
                            <Input placeholder="请输入点击均价" clearable  v-model="formData.djjj" type="number"/>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem  label="备注" prop="remark">
                            <Input placeholder="备注" clearable  v-model="formData.remark" type="textarea"/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
            <Button type="text" @click="edModal = false">取消</Button>
            <Button
                :loading="isLoadingInfoEntry"
                type="primary"
                @click="submit()"
            >提交</Button>
        </div>
        </Modal>

    </div>
</template>
<script>
import DelButton from './../components/DelButton.vue';
export default {    
    name: 'DetailsPage',
    components: {
        DelButton,
    },
    data() {
        return {
            title: '',
            list:[],
            loading:true,
            total:0,
            select:[],
            type: 'table',
            data:[],
            height:0,
            pageSize: 10,
            rows:10,
            tableColumnsChecked: [],
            yema: '',
            setting: false,
            edModal: false,
            isLoadingInfoEntry: false,
            columnsList: [],
            UserList: [],
            isSetting:true,
            searchRuleValidate: {
                user_id: [
                    {
                        required: true,
                        message: '请选择角色',
                    },
                ],
                dates: [
                    {
                        required: true,
                        message: '请选择时间',
                        trigger: 'blur',
                    },
                ],
                types: [
                    {
                        required: true,
                        message: '请填写广告渠道',
                        trigger: 'blur',
                    },
                ],
                amount: [
                    {
                        required: true,
                        message: '请输入广告花费',
                        trigger: 'blur',
                    },
                ],

            },
            columns:null,
            columns1: [
                {
                    type: 'selection',
                    width:70,
                    align: 'center'
                },
                {
                    title: '序号',
                    type: 'index',
                    width:70,
                    align: 'center'
                },
                {
                    title: '日期',
                    key: 'dates',
                    align: "center",
                },{
                    title: '广告渠道',
                    key: 'types',
                    align: "center",
                },{
                    title: '广告花费（$）',
                    key: 'amount',
                    align: "center",
                    render: (h, params) => {
                        return h('div',
                        {
                            
                        },`$ ${params.row.amount||'0'}`)
                            
                    }
                },{
                    title: 'CPM',
                    key: 'cpm',
                    align: "center",
                },{
                    title: '展示数',
                    key: 'zss',
                    align: "center",
                },{
                    title: '点击数',
                    key: 'djs',
                    align: "center",
                },{
                    title: '点击率',
                    key: 'djl',
                    align: "center",
                },{
                    title: '点击均价($)',
                    key: 'djjj',
                    align: "center",
                    render: (h, params) => {
                        return h('div',
                        {
                            
                        },`$ ${params.row.djjj||'0'}`)
                            
                    }
                },{
                    title: '备注',
                    key: 'remark',
                    align: "center",
                    render: (h, params) => {
                        return h('Tooltip',
                        {
                            props: {
                                'max-width': '500',
                                
                                content: params.row.remark,
                                placement: 'top',
                            }

                        },[
                            h('div',[
                                h('span',
                                    {
                                        style: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            '-webkit-line-clamp': 2,
                                            '-webkit-box-orient': 'vertical',
                                        },
                                    },
                                    params.row.remark
                                ),
                            ])
                        ])
                            
                    }
                },{
                    title: '用户',
                    key: 'name',
                    align: "center",
                },{
                    title: "修改",
                    key: "xiugai",
                    width: 100, 
                    align: "center",
                    fixed:"right",
                    render: (h, params) => {
                        return h('Button',
                        {
                            on:{
                                click: () => {
                                    this.edModal = true;
                                    this.title = '修改';
                                    this.formData = params.row;
                                    this.$set(this.formData, 'date', params.row.dates)
                                }
                            },
                            props: {
                                data: params.row
                            },
                        },'修改')
                    }
                },
            ],
            filters: {
                times1: "",
                times2: "",
                types: "",
                amount: "",
                remark: "",
                user_id: "",
            },
            formData:{
                date: "",
                dates: "",
                types: "",
                amount: "",
                remark: "",
                cpm: "",
                zss: "",
                djs: "",
                djl: "",
                djjj: "",
                user_id: "",
            },
            admin:false,
            page: 1,
        }
    },
    created(){
        
        this.getListData()
    },
    methods:{
        
        initFilters(){
            for (let index in this.columns) {
                if (!this.columns[index].type) {
                    this.tableColumnsChecked.push(this.columns[index].key)
                }
            }
            this.changeTableColumns()
        },
        filterReset() {
            this.filters = {
                times1: "",
                times2: "",
                types: "",
                amount: "",
                remark: "",
            };
        },
        setTime(time){
            this.formData.dates = time
        },
        add(){
            this.edModal = true;
            this.title = '新增';
            this.list = [];
            this.formData = {}
        },
        search() {
            this.page = 1
            this.getListData()
        },
        refresh() {
            this.getListData();
            this.select = [];
        },
        setpage() {
            let num = Number(this.yema)
            if (!isNaN(num) && num != 0) {
                this.page = num
                this.getListData()
            }
        },
        async submit(){
            this.isLoadingInfoEntry = true
            let valid = await this.$refs.form.validate()
            if (valid) {
                let res = await this.$api.api.add(this.formData)
                
                if(res.code==1){
                    this.$Message.success(res.message)
                    this.edModal = false;
                    this.formData = {
                        date: "",
                        dates: "",
                        types: "",
                        amount: "",
                        remark: "",
                        cpm: "",
                        zss: "",
                        djs: "",
                        djl: "",
                        djjj: "",
                        user_id: "",
                    }
                    this.getListData()
                }else{
                    this.$Message.error(res.message)
                }
                this.isLoadingInfoEntry = false
            }else{
                this.isLoadingInfoEntry = false
                return this.$Message.error('验证失败')
            }
            
        },
        sortChange(){

        },
        selectOption(val){
            this.formData.user_id = val
        },
        changeTableColumns() {
            this.columnsList = this.columns.concat()
            const that = this
            for (let index in this.columns) {
                let column = that.columns[index]
                if (!column.type && !this.tableColumnsChecked.includes(column.key)) {
                    let i = this.columnsList.findIndex(item => {
                        return item.key == column.key
                    })
                    this.columnsList.splice(i, 1)
                }
            }
        },
         //分页
        changePage(page) {
        this.page = page
        this.getListData()
        },
        async getListData(){
            this.loading =true
            if(this.filters.times1)
                this.filters.times1 = this.$moment(this.filters.times1).format('YYYY-MM-DD')
            if(this.filters.times2)
                this.filters.times2 = this.$moment(this.filters.times2).format('YYYY-MM-DD')
            let obj = Object.assign({}, this.filters,{
                page:this.page,
                rows:this.rows,
            });
            let res = await this.$api.api.getlist(obj);
            
            if(res.code==1){
                this.loading =false
                this.data = res.data;
                if(this.data.length>0){
                    if('name' in res.data[0]){
                        if(this.UserList.length==0){
                            this.getUser();
                        }
                        
                        this.columns = this.columns1
                        this.admin = true
                    }else{
                        this.admin = false
                        this.columns = this.columns1.filter(item => 
                            item.key !== 'name' && item.key !== 'xiugai'
                        )
                    }
                }else{
                    this.admin = false
                    this.columns = this.columns1.filter(item => 
                        item.key !== 'name' && item.key !== 'xiugai'
                    )
                }
                
                this.initFilters()
                if(res.data.length>=15)
                {
                    this.height = 700
                }else{
                    this.height = 0
                }
                this.total = res.total;
                this.isPage = this.total && this.total > this.pageSize
            }else{
                this.loading =false
                this.$Message.error(res.message);
            }
        },
        async getUser(){
            let res = await this.$api.api.getUser();
            if(res.code==1){
                this.UserList = res.data
            }

        },
        selectRow(select) {
            this.select = select.map((item) => {
                return item.id;
            });
        },
        
         //每页显示记录数
        changePageSize(pageSize) {
            this.rows = pageSize
            this.getListData()
        },
        detail(){
            
        },
        afterLoad() {},
    }
}
</script>
<style scoped>
    /* 头部样式 */
    header {
            background-color: #333;
            color: white;
            text-align: center;
            padding: 20px;
            position: relative;
        }
        .back{
            position: absolute;
            left: 20px;
            font-size: 16px;
            cursor: pointer;
        }
        .back:hover{
            color: red;
        }
        /* 主要内容区域样式 */
        .main-content {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 100px);
            overflow-y: auto;
            padding: 20px;
        }

      
        /* 盒子样式 */
        .fund-box {
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 5px;
           
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .fund-box1 {
            flex: 1;
            display:flex ;
            flex-direction: column;
        }
        /* 表格样式 */
        table {
            flex: 1;
            border-collapse: collapse;
            height: 100%;
        }
        th,
        td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f5f5f5;
        }

        /* 按钮样式 */
        .btn {
            display: inline-block;
            background-color: #007BFF;
            color: white;
            padding: 8px 16px;
            width: fit-content !important;
            border-radius: 9px;
            text-decoration: none;
            transition: background-color 0.3s ease;
            margin-bottom: 10px; /* 添加底部边距 10 像素 */
        }

        .btn:hover {
            background-color: #0056b3;
        }

        .footer {
            background-color: #fff;
            overflow: hidden;
            width: 100%;
            padding: 0px 10px;
            height: 53px;
            line-height: 53px;
     
            margin-top: -10px;
        }
        /deep/ .ivu-page .ivu-page-options {
    margin-top: -3px;
  }
  .tiaozhuan {
    float: left;
  }
  .searchButWrap {
  float: right;
  padding: 0px 10px;
  margin-top: -2px;
}

</style>
