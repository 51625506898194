import { get, post, checkImageLoad, serverURL } from './index.js'
export default {
    getAmount: data => get(serverURL + '/list/getAmount', data),
    getUser: data => get(serverURL + '/user/getUser', data),
    login: data => get(serverURL + '/user/login', data),
    logout: data => get(serverURL + '/user/logout', data),
    getuserinfo: data => get(serverURL + '/user/userinfo', data),
    getloginLog: data => get(serverURL + '/list/loginLog', data),
    getamountLog: data => get(serverURL + '/list/amountLog', data),
    getlist: data => get(serverURL + '/list/list', data),
    del: data => get(serverURL + '/list/del', data),

    sginup: data => post(serverURL + '/user/sginup', data, {
        transformRequest: function (data) {
            return JSON.stringify(data)
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }),
    addAmount: data => post(serverURL + '/list/addAmount', data, {
        transformRequest: function (data) {
            return JSON.stringify(data)
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }),
    add: data => post(serverURL + '/list/add', data, {
        transformRequest: function (data) {
            return JSON.stringify(data)
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }),
    setPwd: data => post(serverURL + '/list/setPwd', data, {
        transformRequest: function (data) {
            return JSON.stringify(data)
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    }),
    checkImageLoads: data => checkImageLoad(data),
}