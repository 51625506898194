<template>
    <div>
        <Drawer title="设置" :closable="false" width="640" v-model="setting">
            <Form label-position="top">
                <FormItem label="字段" v-if="type == 'table'">
                    <Checkbox-group v-model="tableColumnsChecked" @on-change="changeTableColumns">
                        <Row>
                            <Col span="8" v-for="column in columns" :key="column.key">
                                <span v-if="column.title && column.type!== 'index'">
                                    <Checkbox :label="column.key">{{column.title}}</Checkbox>
                                </span>
                                
                            </Col>
                        </Row>
                    </Checkbox-group>
                </FormItem>
            </Form>
        </Drawer>
        <header>
            <span class="back" @click="$router.go(-1)"> <Icon type="ios-arrow-back" size="16" />返回</span>
            <h1>登录日志</h1>
        </header>
        <div class="main-content">
   
        <Collapse class="search">
            <Panel name="1">
                搜索
                <div class="searchButWrap">
                
                <Button
                    size="small"
                    icon="ios-search"
                    @click.stop="search()"
                    type="primary"
                    style="margin-right: 10px"
                    >搜索</Button
                >
                <Button size="small" @click.stop="filterReset()">清除</Button>
                </div>
                <template #content>
                <Form label-position="top" ref="formValidate" :model="filters">
                    <Row :gutter="16">
                    <Col span="6">
                        <FormItem label="登录时间(开始)">
                            <DatePicker style="width:100%" placeholder="请选择" type="datetime" v-model="filters.times1" format="yyyy-MM-dd HH:mm:ss"/>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="登录时间(结束)">
                            <DatePicker style="width:100%" placeholder="请选择" type="datetime" v-model="filters.times2" format="yyyy-MM-dd HH:mm:ss"/>
                        </FormItem>
                    </Col>
                    <Col span="6" v-if="UserList.length!=0">
                        <FormItem label="用户">
                            <Select style="width:100%" v-model="filters.name" clearable filterable>
                                <Option v-for="item in UserList" :value="item.name" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span="6" v-if="UserList.length!=0">
                        <FormItem label="邮箱">
                            <Input v-model="filters.email" clearable></Input>
                        </FormItem>
                    </Col>
                    
                    </Row>
                </Form>
                </template>
            </Panel>
        </Collapse>
        <div class="fund-box fund-box1">
            <Table 
                width="100%"
                :height="height"
                :columns="columnsList" 
                :data="data"
                v-bind="$attrs"
                v-on="$listeners"
                @on-selection-change="selectRow"
                border
                stripe
                :loading="loading"
                ref="table"
            ></Table>
            <div slot="footer" class="footer">
                <Page
                    :total="total"
                    show-sizer
                    :current="page"
                    :page-size="rows"
                    :page-size-opts="[10, 15, 20, 50, 100, 500]"
                    @on-change="changePage"
                    @on-page-size-change="changePageSize"
                    style="float: left;"
                ></Page>
                <Divider type="vertical" />
                <p class="tiaozhuan">
                    <span>到</span
                    ><Input
                    v-model="yema"
                    style="width: 60px"
                    @on-enter="setpage"
                    @on-blur="setpage"
                    /><span>页</span>
                </p>
                <span
                    >共 <b>{{ total }}</b> 条</span
                >
                <div class="tool" style="float: right;">
                    <Tooltip content="刷新">
                        <Button type="text" @click.native="getListData()" size="small">
                            <Icon type="md-refresh" size="23" />
                        </Button>
                    </Tooltip>
                    <Divider type="vertical" v-if="isSetting" />
                    <Tooltip content="设置" placement="top" v-if="isSetting">
                        <Button type="text" @click="setting = true" size="small">
                            <Icon type="md-settings" size="20" />
                        </Button>
                    </Tooltip>
                </div>
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
export default {    
    name: 'LoginLog',
    data() {
        return {
            title: '',
            list:[],
            loading:true,
            total:0,
            type: 'table',
            data:[],
            height:0,
            pageSize: 10,
            rows:10,
            tableColumnsChecked: [],
            yema: '',
            setting: false,
            edModal: false,
            isLoadingInfoEntry: false,
            columnsList: [],
            UserList: [],
            isSetting:true,
         
            columns:[
                {
                    title: '序号',
                    type: 'index',
                    width:70,
                    align: 'center'
                },{
                    title: '登录时间',
                    key: 'times',
                    align: "center",
                },
                {
                    title: '用户',
                    key: 'name',
                    align: "center",
                },{
                    title: '邮箱',
                    key: 'email',
                    align: "center",
                }
            ],
           
            filters: {
                times1: "",
                times2: "",
                name: "",
                email: "",
            },
            admin:false,
            page: 1,
        }
    },
    created(){
        
        this.getListData()
    },
    methods:{
        initFilters(){
            for (let index in this.columns) {
                if (!this.columns[index].type) {
                    this.tableColumnsChecked.push(this.columns[index].key)
                }
            }
            this.changeTableColumns()
        },
        filterReset() {
            this.filters = {
                times1: "",
                times2: "",
                name: "",
                email: "",
            };
        },
        search() {
            this.page = 1
            this.getListData()
        },
        setpage() {
            let num = Number(this.yema)
            if (!isNaN(num) && num != 0) {
                this.page = num
                this.getListData()
            }
        },
        
        changeTableColumns() {
            this.columnsList = this.columns.concat()
            const that = this
            for (let index in this.columns) {
                let column = that.columns[index]
                if (!column.type && !this.tableColumnsChecked.includes(column.key)) {
                    let i = this.columnsList.findIndex(item => {
                        return item.key == column.key
                    })
                    this.columnsList.splice(i, 1)
                }
            }
        },
         //分页
        changePage(page) {
        this.page = page
        this.getListData()
        },
        async getListData(){
            this.loading =true
            if(this.filters.times1)
                this.filters.times1 = this.$moment(this.filters.times1).format('YYYY-MM-DD HH:mm:ss')
            if(this.filters.times2)
                this.filters.times2 = this.$moment(this.filters.times2).format('YYYY-MM-DD HH:mm:ss')
            
            let obj = Object.assign({}, this.filters,{
                page:this.page,
                rows:this.rows,
            });
            let res = await this.$api.api.getloginLog(obj);
            
            if(res.code==1){
                this.loading =false
                this.data = res.data;
                if(this.UserList.length==0){
                    this.getUser();
                }
                this.initFilters()
                if(res.data.length>=15)
                {
                    this.height = 700
                }else{
                    this.height = 0
                }
                this.total = res.total;
                this.isPage = this.total && this.total > this.pageSize
            }else{
                this.loading =false
                this.$Message.error(res.message);
            }
        },
        async getUser(){
            let obj = {
                all:"all"
            }
            let res = await this.$api.api.getUser(obj);
            if(res.code==1){
                this.UserList = res.data
            }

        },
       
         //每页显示记录数
        changePageSize(pageSize) {
            this.rows = pageSize
            this.getListData()
        },
        detail(){
            
        },
        afterLoad() {},
    }
}
</script>
<style scoped>
    /* 头部样式 */
    header {
            background-color: #333;
            color: white;
            text-align: center;
            padding: 20px;
            position: relative;
        }
        .back{
            position: absolute;
            left: 20px;
            font-size: 16px;
            cursor: pointer;
        }
        .back:hover{
            color: red;
        }
        /* 主要内容区域样式 */
        .main-content {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 100px);
            overflow-y: auto;
            padding: 20px;
        }

      
        /* 盒子样式 */
        .fund-box {
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 5px;
           
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .fund-box1 {
            flex: 1;
            display:flex ;
            flex-direction: column;
        }
        /* 表格样式 */
        table {
            flex: 1;
            border-collapse: collapse;
            height: 100%;
        }
        th,
        td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f5f5f5;
        }

        /* 按钮样式 */
        .btn {
            display: inline-block;
            background-color: #007BFF;
            color: white;
            padding: 8px 16px;
            width: fit-content !important;
            border-radius: 9px;
            text-decoration: none;
            transition: background-color 0.3s ease;
            margin-bottom: 10px; /* 添加底部边距 10 像素 */
        }

        .btn:hover {
            background-color: #0056b3;
        }

        .footer {
            background-color: #fff;
            overflow: hidden;
            width: 100%;
            padding: 0px 10px;
            height: 53px;
            line-height: 53px;
     
            margin-top: -10px;
        }
        /deep/ .ivu-page .ivu-page-options {
    margin-top: -3px;
  }
  .tiaozhuan {
    float: left;
  }
  .searchButWrap {
  float: right;
  padding: 0px 10px;
  margin-top: -2px;
}

</style>
