<template>
    <div>
        <header >
            <h1>资金详情主页</h1>
            <div class="user-info">
               
                <Dropdown @on-click="showVersion">
                    <div class="info">
                        <Avatar v-if="!avatarbol" style="color: #f56a00;background-color: #fde3cf" size="large">{{avatars[0]}}</Avatar>
                        <viewer v-if="avatarbol && avatars.length>0" :options="options" :images="avatars" >
                            <template scope="scope">
                                <p style="position:relative;height:45px;width:45px">
                                    <img :src="scope.images" style="position:relative;border:1px solid #eeeeee;object-fit: cover;border-radius: 50%;height:45px;width:45px" alt="">
                                </p>
                            </template>
                        </viewer>
                        <span class="name">{{ userinfo.name }}</span>
                    </div>
                <DropdownMenu slot="list">
                <DropdownItem name="loginLog">登录日志</DropdownItem>
                <DropdownItem name="amountLog" v-if="totals>1">资金修改日志</DropdownItem>
                <DropdownItem name="updatePwd">修改密码</DropdownItem>
                <DropdownItem divided name="logout">退出</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </div>
        </header>
        <div class="main-content">
            <!-- 资金总览盒子 -->
            
            <div class="fund-box" style="overflow: hidden;">
                <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 10px;">
                    <h2>资金总览</h2>
                    <div> 
                        <Tooltip content="刷新">
                            <Button type="text" @click.native="getAmount()" size="small" style="margin-right: 20px;">
                                <Icon type="md-refresh" size="25" />
                            </Button>
                        </Tooltip>
                        <Button  type="info" @click="$router.push({ name: 'Showdetails' })" :disabled="totals<2">查看所有</Button>
                    </div>
                </div>
                <div style="display: flex;position: relative;">
                    <div v-if="amountlist.length>0" style="display: flex;position: relative;">
                        <Card style="min-width:290px;height: 100%;margin:5px 8px;" v-for="item in amountlist" :key="item.id">
                            <div @click="addAmount(item)" style="cursor: pointer;">
                                <p>用户账号：<Tag color="blue">{{item.name}}</Tag></p>
                                <p>用户邮箱：<Tag color="green">{{item.email}}</Tag></p>
                                <p>充值总额：<Tag color="red">$<countTo :startVal='0' :decimals="2" id="countTo" :endVal="Number(item.amount1)||0" :duration='6000' :separator="''"></countTo ></Tag></p>
                                <p>余额：<Tag color="purple">$<countTo :startVal='0' :decimals="2" id="countTo" :endVal="Number(item.amount2)||0" :duration='6000' :separator="''"></countTo ></Tag></p>
                                <p v-if="item.times">修改时间：<Tag color="cyan">{{ item.times }}</Tag></p>
                                <p v-if="item.updatename">修改账号：<Tag color="orange">{{ item.updatename }}</Tag></p>
                            </div>
                        </Card>
                    </div>
                    <Spin style="height: 170px;" v-if="amountlistloading" fix></Spin>
                </div>
                
            </div>

            <!-- 近期交易记录盒子 :loadData="$api.dailyDuty.listYylbData"-->
        
            <div class="fund-box fund-box1">
                
                <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 10px;">
                    <h2>消耗记录</h2>
                    <div> 
                        <Tooltip content="刷新">
                            <Button type="text" @click.native="getListData()" size="small" style="margin-right: 20px;">
                                <Icon type="md-refresh" size="25" />
                            </Button>
                        </Tooltip>
                        <Button type="info" @click="$router.push({ name: 'Details' })">更多记录</Button>
                    </div>
                    
                </div>
                <Table 
                    width="100%"
                    :height="0"
                    :columns="columns" 
                    :data="data"
                    v-bind="$attrs"
                    v-on="$listeners"
                    border
                    :loading="loading"
                
                    ref="table"
                ></Table>
                
            </div>

        </div>

        <Modal v-model="amountModal" title="修改">
            <Form :model="amountData" :rules="amountRuleValidate" ref="forms">
                <Row :gutter="24">
                    <Col span="24">
                        <FormItem label="用户账号">
                            <Input placeholder="请输入广告渠道"  v-model="amountData.name" icon="md-person" disabled/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem  label="充值总额" prop="amount1">
                            <Input placeholder="请输入充值总额"  v-model="amountData.amount1" type="number"/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem  label="修改时间">
                            <Input v-model="amountData.times" icon="md-clock" disabled/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem label="修改账号">
                            <Input v-model="amountData.updatename" icon="ios-paw" disabled/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
            <Button type="text" @click="amountModal = false">取消</Button>
            <Button
                :loading="amountInfoEntry"
                type="primary"
                @click="amountSubmit()"
            >提交</Button>
        </div>
        </Modal>
        <modal v-model="isUpdatePwd" title="修改密码">
            <div>
                <i-form ref="formCustom" :model="formCustom" :rules="ruleCustom">
                <form-item prop="password">
                    <Input
                    type="password"
                    password
                    placeholder="原密码"
                    v-model="formCustom.password"
                    ></Input>
                </form-item>
                <form-item prop="newPwd">
                    <Input
                    type="password"
                    placeholder="新密码"
                    password 
                    v-model="formCustom.newPwd"
                    ></Input>
                </form-item>
                <form-item prop="pwd">
                    <Input
                    type="password"
                    password 
                    placeholder="再次输入新密码"
                    v-model="formCustom.pwd"
                    ></Input>
                </form-item>
                </i-form>
            </div>
            <div slot="footer">
                <Button type="text" @click="isUpdatePwd = false">关闭</Button>
                <Button type="primary" :loading="loadingPwd" @click="handleClickSave"
                >确定</Button
                >
            </div>
        </modal>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {    
    name: 'HomePage',
    components: {
        countTo
    },
    data() {
        return {
            list:[],
            totals:0,
            options: {
                toolbar: {
                    zoomIn: {
                        size: 'large',
                    },
                    zoomOut: {
                        size: 'large',
                    },
                    oneToOne: {
                        size: 'large',
                    },
                    reset: {
                        size: 'large',
                    },
                    prev: {
                        size: 'large',
                    },
                    play: false,
                    next: {
                        size: 'large',
                    },
                    rotateLeft: {
                        size: 'large',
                    },
                    rotateRight: {
                        size: 'large',
                    },
                },
                zIndex: 999999,
                movable: true,
                zoomable: true,
                rotatable:true,
                navbar: false,
            },
            select: [],
            loading:true,
            amountModal:false,
            loadingPwd:false,
            avatarbol:false,
            amountlistloading:true,
            amountInfoEntry:false,
            amountlist:[],
            isUpdatePwd:false,
            userinfo: "",
            avatars: [],
            amountData:{
                name: "",
                email: "",
                amount1: "",
                times: "",
                updatename: "",
            },
            formCustom: {
                password: "",
                newPwd: "",
                pwd: "",
            },
            ruleCustom: {
                password: [
                    {
                        required: true,
                        message: "原密码不能为空",
                        trigger: "blur",
                    },
                ],
                newPwd: [
                    {
                        required: true,
                        message: "新密码不能为空",
                        trigger: "blur",
                    },
                ],
                pwd: [
                    {
                        required: true,
                        message: "新密码不能为空",
                        trigger: "blur",
                    },
                ],
        },
            amountRuleValidate: {
                amount1: [
                    {
                        required: true,
                        message: '请设置充值总额',
                    },
                ],
            },
            data:[],
            columns:[],
            columns1: [
                {   
                    title: '序号',
                    type: 'index',
                    width:70,
                    align: 'center'
                },
                {
                    title: '日期',
                    key: 'dates',
                    align: "center",
                },{
                    title: '广告渠道',
                    key: 'types',
                    align: "center",
                },{
                    title: '广告花费（$）',
                    key: 'amount',
                    align: "center",
                    render: (h, params) => {
                        return h('div',
                        {
                            
                        },`$ ${params.row.amount||'0'}`)
                            
                    }
                },{
                    title: 'CPM',
                    key: 'cpm',
                    align: "center",
                },{
                    title: '展示数',
                    key: 'zss',
                    align: "center",
                },{
                    title: '点击数',
                    key: 'djs',
                    align: "center",
                },{
                    title: '点击率',
                    key: 'djl',
                    align: "center",
                },{
                    title: '点击均价($)',
                    key: 'djjj',
                    align: "center",
                    render: (h, params) => {
                        return h('div',
                        {
                            
                        },`$ ${params.row.djjj||'0'}`)
                            
                    }
                },{
                    title: '备注',
                    key: 'remark',
                    align: "center",
                    render: (h, params) => {
                        return h('Tooltip',
                        {
                            props: {
                                'max-width': '500',
                                content: params.row.remark,
                                placement: 'top',
                            }

                        },[
                            h('div',[
                                h('span',
                                    {
                                        style: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            '-webkit-line-clamp': 2,
                                            '-webkit-box-orient': 'vertical',
                                        },
                                    },
                                    params.row.remark
                                ),
                            ])
                        ])
                            
                    }
                },{
                    title: '用户',
                    key: 'name',
                    align: "center",
                },
            ]
     
        }
    },
    created(){
        this.getAmount()
        this.getListData()
        this.load()
    },
    methods:{
        isNumber(value) {
            return Number.isFinite(value);
        },
        async load() {
            let res = await this.$api.api.getuserinfo();
            if(res.code==1){
                this.userinfo = res.data[0];
                this.getUserInfos()
            }else{
                this.$Message.error(res.message);
            }
        },
        getUserInfos(){
            
        
            if(this.isQQEmail(this.userinfo.email)){
                let qqNumber = this.getQQNumber(this.userinfo.email)
                if(qqNumber){
                    this.avatars =[`https://thirdqq.qlogo.cn/g?b=qq&nk=${qqNumber}&s=640`] 
                    this.$api.api.checkImageLoads(this.avatars[0]).then(isLoaded => {
                        if(isLoaded){
                            this.avatarbol = true
                        }else{
                            this.avatarbol = false
                            this.avatars = [this.userinfo.name.slice(0,2)]
                        }
                    });
                }else{
                    this.avatars = [this.userinfo.name.slice(0,2)]
                    this.avatarbol = false
                }
            }else{
                this.avatars = [this.userinfo.name.slice(0,2)]
                this.avatarbol = false
            }
        },
        async handleClickSave() {
            this.loadingPwd = true;
            let validate = await this.$refs.formCustom.validate();
            if (validate) {
                try {
                    if(this.formCustom.newPwd !== this.formCustom.pwd){
                        this.$Message.error("两次密码输入不一致");
                        this.loadingPwd = false;
                        return;
                    }
                    let res = await this.$api.api.setPwd(this.formCustom);
                    this.loadingPwd = false;
                    if (res.code == 1) {
                        this.$Message.success("修改成功，请重新登录");
                        localStorage.removeItem('token');
                        localStorage.removeItem('userinfo');
                        this.$router.replace({ name: 'login'});
                    } else {
                        this.$Message.error(res.message);
                    }
                } finally {
                    this.loadingPwd = false;
                }
            }
            this.loadingPwd = false;
        },
        async showVersion(name) {
            if (name == "logout") {
                await this.$api.api.logout();
                localStorage.removeItem('token');
                localStorage.removeItem('userinfo');
                this.$router.replace({ name: 'login'});
            }else if(name == "loginLog"){
                this.$router.push({ name: 'LoginLog'});
            }else if(name == "amountLog"){
                this.$router.push({ name: 'FundLog'});
            }else{
                this.isUpdatePwd = true
            }
        },
        isQQEmail(email) {
            const qqEmailPattern = /^[a-zA-Z0-9._%+-]+@(qq\.com|foxmail\.com)$/;
            return qqEmailPattern.test(email);
        },
        getQQNumber(email) {
            const atIndex = email.indexOf('@'); 
            if (atIndex !== -1) {
                return email.slice(0, atIndex); 
            }
            return null;
        },
        async addAmount(item){
            if(item.times){
                this.amountData = item
                this.amountModal = true
            }
            
        },
        async amountSubmit(){
            this.amountInfoEntry = true
            let valid = await this.$refs.forms.validate()
            if (valid) {
                let res = await this.$api.api.addAmount(this.amountData);
                    this.$Message.success(res.message)
                    this.amountInfoEntry = false
                    if(res.code==1){
                        this.amountModal = false
                        this.getAmount()
                    }
            }else{
                this.amountInfoEntry = false
                this.$Message.error('验证失败')
            }
        },
        async getAmount(){
            let obj = {
                page:1,
                rows:7
            }
            this.amountlist = []
            this.amountlistloading =true
            let ress = await this.$api.api.getAmount(obj);
            if(ress.code==1){
                this.amountlist = ress.data
                this.totals = ress.total
            }
            this.amountlistloading = false
        },
        async getListData(){
            this.columns = this.columns1.slice(0, 4);
            let obj = {
                page:1,
                rows:10
            }
            let res = await this.$api.api.getlist(obj);
            this.loading =true
            if(res.code==1){
                this.loading =false
                this.data = res.data;
                if('name' in res.data[0]){
                    this.columns = this.columns1
                }else{
                    this.columns = this.columns1.filter(item => 
                        item.key !== 'name'
                    )
                }
            }else{
                this.loading =false
                this.$Message.error(res.message);
            }
        },
        selectRow(select) {
            this.select = select.map((item) => {
            return item.id;
            });
        },
        detail(){
            
        },
        afterLoad() {},
    }
}
</script>
<style scoped>
    /* 头部样式 */
    header {
            background-color: #333;
            color: white;
            text-align: center;
            padding: 20px;
            min-width: 1200px;
            position: relative;
        }
        .user-info{
            position: absolute;
            top: 20px;
            right: 50px;
            display: flex;
            align-items: center;
            height: 50px;
        }
        /* 主要内容区域样式 */
        .main-content {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 50px);
            padding: 20px;
            overflow-y: auto;
            overflow-x: hidden;
        }

      
        /* 盒子样式 */
        .fund-box {
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            padding: 20px;
            min-width: 1200px;
            margin-bottom: 20px;
            border-radius: 5px;
            flex-shrink: 0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .fund-box1 {
            flex: 1;
            display:flex ;
            flex-direction: column;
        }
        /* 表格样式 */
        table {
            flex: 1;
            border-collapse: collapse;
            height: 100%;
        }
        th,
        td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f5f5f5;
        }

        /* 按钮样式 */
        .btn {
            display: inline-block;
            background-color: #007BFF;
            color: white;
            padding: 8px 16px;
            width: fit-content !important;
            border-radius: 9px;
            cursor: pointer;
            text-decoration: none;
            transition: background-color 0.3s ease;
            margin-bottom: 10px; /* 添加底部边距 10 像素 */
        }
        .info {
            display: flex;
            align-items: center;
        }
        .name {
            color: #fff;
            margin-left: 10px;
            font-size: 16px;
        }
        .btn:hover {
            background-color: #0056b3;
        }
        
</style>