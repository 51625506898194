<template>
    <div class="container">
        <div class="blob"></div>
        <div class="wrapper">
            <h2>注册页面</h2>
             <div class="input-box">
                <span class="icon"><ion-icon name="person-outline"></ion-icon></span>
                <input type="text" v-model="name" required>
                <label >账号</label>
            </div>
             <div class="input-box">
                 <span class="icon"><ion-icon name="mail-open-outline"></ion-icon></span>
                 <input type="eamil" v-model="eamil"  required>
                 <label >邮箱</label>
             </div>
             <div class="input-box">
                 <span class="icon"><ion-icon name="lock-closed-outline"></ion-icon></span>
                 <input type="password" v-model="password" required>
                 <label >密码</label>
             </div>
             <div class="remeber-forgot">
                 <label>
                     <input type="checkbox" v-model="remember"> 我同意所有条款
                 </label>
             </div>
             <button type="submit" class="submit" @click="login()">注册按钮</button>
             <div class="register-link">
                 <p>已有账号? <span style="cursor: pointer;" @click="$router.replace({ name:'login'})">点击登录</span></p>
             </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserSginup',
    data(){
        return{
            name:null,
            eamil:null,
            password:null,
            remember:false,
        }
    },
    methods:{
        async login(){
            if(this.remember){
                if(!this.name || !this.password || !this.eamil){
                    this.$Message.error("用户名、密码、邮箱不能为空");
                    return
                }
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if(!emailPattern.test(this.eamil)){
                    this.$Message.error("邮箱格式不正确");
                    return
                }

                let obj = {
                    name:this.name,
                    email:this.eamil,
                    password:this.password
                }

                let res = await this.$api.api.sginup(obj);
                if(res.code==1){
                    this.$Message.success("注册成功");
                    this.$router.replace({ name:'login'})
                }else{
                    this.$Message.error(res.message);
                }
            }else{
                this.$Message.error("请勾选我同意所有条款");
            }
            
        },
    }
}
</script>
<style scoped>



.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #1f1f47;
    overflow: hidden;
}

.wrapper{
    position: relative;
    width: 400px;
    height: 450px;
    background:rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

h2{
    font-size: 2em;
    color: #fff;
    text-align: center;
}

.input-box{
    position: relative;
    width: 320px;
    margin: 10px 0;
    border-bottom: 2px solid #fff;
}

.input-box label{
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    color: #fff;
}

.input-box input{
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
    padding: 0 35px 0 5px;
}

.input-box input:focus~label,
.input-box input:valid~label{
    top: 0px;
}

.input-box .icon{
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 16px;
    line-height: 57px;
}

.remeber-forgot{
    margin: 15px 0 15px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: space-between;
}
.remeber-forgot a{
    color: #fff;
    text-decoration: none;
}
.remeber-forgot a:hover{
    text-decoration: underline;
}
.remeber-forgot label input{
    accent-color: #fa709a;
    margin-right: 3px;
}
button{
    width: calc(100% - 70px);
    padding: 10px 0;
    background: #fa709a;
    border: none;
    outline: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}
.register-link{
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin: 25px 0 10px;
}
.register-link a{
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register-link  a:hover{
    text-decoration: underline;
}

.blob{
    position: absolute;
    width: 500px;
    height: 500px;
    background: linear-gradient(180deg,rgba(47,184,255,0.42),#5c9df1 100%);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
    transform: translate(-100px,-50px) rotate(-90deg);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    animation: animateBlob 20s infinite alternate;
}

.blob:hover{
    width: 520px;
    height: 520px;
    filter: blur(30px);
    box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0 #fa709a,
    inset 200px 200px 0 0 #784ba8, 
    inset 300px 300px 0 0 #2b86c5;
}

@keyframes animateBlob{
    100%{
        transform: translate(300px ,50px) rotate(-10deg);
        border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
    }
}

</style>