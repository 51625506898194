const lang = {
    login: {
        title: "Form information platform",
        login_btn: 'Login',
        username_label: 'Username',
        password_label: 'Password',
        remember_me_label: 'Remember password',
        forget_password_label: 'Forgot password',
        username_label1: 'Please enter a username',
        password_label1: 'Please enter your password',
    }
}
export default lang;