<template>
<Poptip confirm title="确认删除这条记录吗？" :disabled="delLoading" @on-ok="ok" placement="bottom-end">
    <Tooltip content="选择要删除的记录" :disabled="ids.length > 0">
        <Button v-bind="$attrs" :type="type" :loading="delLoading" :disabled="ids.length <= 0" :icon="icon" :size="size">
            <slot>
                <span>{{text}}</span>
            </slot>
        </Button>
    </Tooltip>
</Poptip>
</template>

<script>
export default {
    props: {
        ids: {
            type: Array,
            default: () => []
        },
        api: {
            type: Function
        },
        keyField: {
            type: String,
            default: "ids"
        },
        styleType: {
            type: String,
            default: "form",
            validator: function (value) {
                // 这个值必须匹配下列字符串中的一个
                return ['tree', 'table', 'form', "small"].indexOf(value) !== -1
            }
        }
    },
    data() {
        return {
            delLoading: false,
            params: {},
            icon: "",
            text: "删除",
            size: "default",
            type: "error"
        }
    },
    methods: {
        async ok() {
            this.delLoading = true

            let params = Object.assign({}, this.params, {
                [this.keyField]: this.ids.join(',')
            })
            this.$emit('on-before-load', params)

            let res = await this.api(params)

            if (res.code==1) {
                this.$Message.success("删除成功");
                this.$emit("on-success", res)
            } else {
                this.$Message.error("删除失败，" + res.message);
            }

            this.delLoading = false
        }
    },
    created() {
        switch (this.styleType) {
            case "tree":
                this.icon = ""
                this.size = "small"
                this.type = "text"
                break;

            case "table":
                this.icon = ""
                this.size = "small"
                break;
            case "small":
                this.icon = "md-close"
                this.size = "small"
                break;
            case "del":
                this.icon = "md-trash"
                this.size = "small"
                break;
            default:
                break;
        }
    },
}
</script>

<style lang="less" scoped>

</style>
