

const routeType = process.env.VUE_APP_NAME;

async function loadRouter() {
    let router;

    switch (routeType) {
        case 'list':
            router = await import('./router_list');
            break;
        case 'form':
        case 'static':
            router = await import('./router_froms');
            break;
        case 'froms_console':
            router = await import('./router_froms_console');
            break;
        default:
            throw new Error(`Unknown route type: ${routeType}`);
    }

    return router.default;
}
export { loadRouter }