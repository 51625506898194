import Vue from 'vue'
import store from '../store/index';
import Router from 'vue-router'
import login from '../views/UserLogin.vue'
import UserSginup from '../views/UserSginup.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import Home from '../views/Home.vue'
import Details from '../views/Details.vue'
import Details1 from '../views/Details1.vue'
import Config from '../views/Config.vue'
import LoginLog from '../views/LoginLog.vue'
import FundLog from '../views/FundLog.vue'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'hash',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/Config',
      name: 'Config',
      component: Config,
    },
    {
      path: '/sginup',
      name: 'UserSginup',
      component: UserSginup,
    },
    {
      path: '/forgotpass',
      name: 'ForgotPassword',
      component: ForgotPassword,
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/details',
      name: 'Details',
      component: Details,
    },
    {
      path: '/showdetails',
      name: 'Showdetails',
      component: Details1,
    },
    {
      path: '/loginlog',
      name: 'LoginLog',
      component: LoginLog,
    },
    {
      path: '/fundlog',
      name: 'FundLog',
      component: FundLog,
    },
    // {
    //   path: '/ditupeizhi',
    //   name: 'ditupeizhi',
    //   //component: Main2,
    //   component: () => import('./views/peizhi/ditupeizhi.vue'),
    // },
  ],
})

router.beforeEach((to, from, next) => {
  console.log(to.path);

  if (to.path === '/Config' || to.path === '/login' || to.path === '/sginup' || to.path === '/forgotpass') {
    next();
  } else {
    if (!store.state.token) {
      Vue.prototype.$message.error('请先登录！');
      next('/login');
    } else {
      next();
    }

  }
});

export default router;