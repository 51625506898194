<template>
    <div>
        <header>
            <span class="back" @click="$router.go(-1)"> <Icon type="ios-arrow-back" size="16" />返回</span>
            <h1>交易记录页面</h1>
        </header>
        <Collapse class="search">
            <Panel name="1">
                搜索
                <div class="searchButWrap">
                
                <Button
                    size="small"
                    icon="ios-search"
                    @click.stop="search()"
                    type="primary"
                    style="margin-right: 10px"
                    >搜索</Button
                >
                <Button size="small" @click.stop="filterReset()">清除</Button>
                </div>
                <template #content>
                <Form label-position="top" ref="formValidate" :model="filters">
                    <Row :gutter="16">
                    <Col span="6">
                        <FormItem label="用户账号">
                            <Select style="width:100%" v-model="filters.user_id" clearable filterable>
                                <Option v-for="item in UserList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="充值总额">
                            <InputNumber
                            :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                             v-model="filters.amount1" clearable
                             style="width:100%"></InputNumber>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="余额">
                            <InputNumber
                            :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')" 
                             v-model="filters.amount2" clearable
                             style="width:100%"></InputNumber>
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="修改时间（开始）">
                            <DatePicker style="width:100%" placeholder="请选择" type="datetime" v-model="filters.times1" format="yyyy-MM-dd HH:mm:ss"  />
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="修改时间（结束）">
                            <DatePicker style="width:100%" placeholder="请选择" type="datetime" v-model="filters.times2" format="yyyy-MM-dd HH:mm:ss"  />
                        </FormItem>
                    </Col>
                    <Col span="6">
                        <FormItem label="修改账号">
                            <Select style="width:100%" v-model="filters.updatename" clearable filterable>
                                <Option v-for="item in UserList" :value="item.name" :key="item.id">{{ item.name }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                    </Row>
                </Form>
                </template>
            </Panel>
        </Collapse>
        <div class="fund-box" style="overflow-y: auto;">
            <div style="display: flex;position: relative;flex-wrap: wrap;">
                <Card style="min-width:290px;height: 100%;margin:5px 8px;" v-for="item in amountlist" :key="item.id">
                    <div @click="addAmount(item)" style="cursor: pointer;">
                        <p>用户账号：<Tag color="blue">{{item.name}}</Tag></p>
                        <p>用户邮箱：<Tag color="green">{{item.email}}</Tag></p>
                        <p>充值总额：<Tag color="red">$<countTo :startVal='0' :decimals="2" id="countTo" :endVal="item.amount1" :duration='6000' :separator="''"></countTo ></Tag></p>
                        <p>余额：<Tag color="purple">$<countTo :startVal='0' :decimals="2" id="countTo" :endVal="item.amount2" :duration='6000' :separator="''"></countTo ></Tag></p>
                        <p>修改时间：<Tag color="cyan">{{ item.times }}</Tag></p>
                        <p>修改账号：<Tag color="orange">{{ item.updatename }}</Tag></p>
                    </div>
                </Card>
                <Spin size="large" v-if="amountlistloading" fix></Spin>
                <span style="width: 100%;text-align: center;" v-if="amountlist.length==0 || !amountlist">暂无数据</span>
            </div>
        </div>
        <Modal v-model="amountModal" title="修改">
            <Form :model="amountData" :rules="amountRuleValidate" ref="forms">
                <Row :gutter="24">
                    <Col span="24">
                        <FormItem label="用户账号">
                            <Input placeholder="请输入交易类型"  v-model="amountData.name" icon="md-person" disabled/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem  label="充值总额" prop="amount1">
                            <Input placeholder="请输入充值总额"  v-model="amountData.amount1" type="number"/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem  label="修改时间">
                            <Input v-model="amountData.times" icon="md-clock" disabled/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem label="修改账号">
                            <Input v-model="amountData.updatename" icon="ios-paw" disabled/>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div slot="footer">
            <Button type="text" @click="amountModal = false">取消</Button>
            <Button
                :loading="amountInfoEntry"
                type="primary"
                @click="amountSubmit()"
            >提交</Button>
        </div>
        </Modal>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
    name: 'DetailsPage1',
    components: {
        countTo
    },
    data() {
        return {
            amountlist:[],
            amountlistloading:false,
            amountInfoEntry:false,
            amountModal:false,
            amountData:{
                name: "",
                email: "",
                amount1: "",
                times: "",
                updatename: "",
            },
            amountRuleValidate: {
                amount1: [
                    {
                        required: true,
                        message: '请设置充值总额',
                    },
                ],
            },
            filters: {
                name: "",
                email: "",
                amount1: "",
                times1: "",
                times2: "",
                updatename: "",
            },
            UserList:[]
        }
    },
    created() {
        this.getAmount()
    },
    methods: {
        search() {
            this.getAmount()
        },
        async addAmount(item){
            this.amountData = item
            this.amountModal = true
        },
        async amountSubmit(){
            this.amountInfoEntry = true
            let valid = await this.$refs.forms.validate()
            if (valid) {
                let res = await this.$api.api.addAmount(this.amountData);
                this.$Message.success(res.message)
                this.amountInfoEntry = false
                if(res.code==1){
                    this.amountModal = false
                    this.getAmount()
                }
            }else{
                this.amountInfoEntry = false
                this.$Message.error('验证失败')
            }
        },
        async getAmount(){
            this.amountlist = []
            this.amountlistloading = true
            if(this.filters.times1)
                this.filters.times1 = this.$moment(this.filters.times1).format('YYYY-MM-DD HH:mm:ss')
            if(this.filters.times2)
                this.filters.times2 = this.$moment(this.filters.times2).format('YYYY-MM-DD HH:mm:ss')

            let ress = await this.$api.api.getAmount(this.filters);
            if(ress.code==1){
                this.amountlist = ress.data
                if(this.UserList.length==0){
                    this.getUser();
                }
            }
            this.amountlistloading = false
        },
        async getUser(){
            let obj = {
                all:"all"
            }
            let res = await this.$api.api.getUser(obj);
            if(res.code==1){
                this.UserList = res.data
            }

        },
        filterReset() {
            this.filters = {
                name: "",
                email: "",
                amount1: "",
                times1: "",
                times2: "",
                updatename: "",
            };
        },
    }
}
</script>
<style scoped>
     header {
            background-color: #333;
            color: white;
            text-align: center;
            padding: 20px;
            position: relative;
        }
        .back{
            position: absolute;
            left: 20px;
            font-size: 16px;
            cursor: pointer;
        }
        .back:hover{
            color: red;
        }
        /* 主要内容区域样式 */
        .main-content {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 100px);
            overflow-y: auto;
            padding: 20px;
        }

      
        /* 盒子样式 */
        .fund-box {
            background-color: #f9f9f9;
            border: 1px solid #ddd;
            padding: 20px;
            min-width: 1000px;
            margin-bottom: 20px;
            border-radius: 5px;
            height: calc(100vh - 150px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .fund-box1 {
            flex: 1;
            display:flex ;
            flex-direction: column;
        }
        /* 表格样式 */
        table {
            flex: 1;
            border-collapse: collapse;
            height: 100%;
        }
        th,
        td {
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid #ddd;
        }

        th {
            background-color: #f5f5f5;
        }

        /* 按钮样式 */
        .btn {
            display: inline-block;
            background-color: #007BFF;
            color: white;
            padding: 8px 16px;
            width: fit-content !important;
            border-radius: 9px;
            text-decoration: none;
            transition: background-color 0.3s ease;
            margin-bottom: 10px; /* 添加底部边距 10 像素 */
        }

        .btn:hover {
            background-color: #0056b3;
        }

        .footer {
            background-color: #fff;
            overflow: hidden;
            width: 100%;
            padding: 0px 10px;
            height: 53px;
            line-height: 53px;
     
            margin-top: -10px;
        }
        /deep/ .ivu-page .ivu-page-options {
    margin-top: -3px;
  }
  .tiaozhuan {
    float: left;
  }
  .searchButWrap {
  float: right;
  padding: 0px 10px;
  margin-top: -2px;
}
</style>