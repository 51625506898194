import Vue from 'vue'

// 自定义组件
const requireComponents = require.context('@/request', true, /\.js$/)

let apis = {}
requireComponents.keys().map(fileName => {
    // 剥去文件名开头的 `./` 和`.vue`结尾的扩展名
    const componentName = fileName.replace(/^\.\//, '').replace(/\.js$/, '')

    if (componentName != "loader" && componentName != "index") {
        // 获取组件配置
        const componentConfig = requireComponents(fileName)
        // 如果这个组件选项是通过 `export default` 导出的，那么就会优先使用 `.default`，否则回退到使用模块的根。
        apis[componentName] = componentConfig.default || componentConfig
    }
})

// 请求接口
Vue.prototype.$api = apis