import Vue from 'vue'
export const serverURL = process.env.VUE_APP_IP
axios.defaults.baseURL = serverURL;
import axios from "axios";
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
import router from '../router/router_list';
const paths = router.path
import store from '../store/index';

export const getToken = () => {
    return store.state.token; // 从 Vuex Store 中获取 token
};
// 检测 token 是否过期或退出
const checkToken = (response) => {
    console.log(response.data, router);

    if (response.data && response.data.code == -2) {
        if (paths != '/login' && paths != '/signup' && paths != '/forgotpass') {
            store.dispatch('clearToken');
            router.replace({
                name: 'login'
            });
        } else {
            const token = getToken();
            if (token) {
                store.dispatch('clearToken');
                router.replace({
                    name: 'login'
                });
                Vue.prototype.$message.error('无效token，请重新登录');
            } else {
                store.dispatch('clearToken');
                Vue.prototype.$message.error('请先登录');
            }
        }
    }
};



axios.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.token = `${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});


axios.interceptors.response.use(response => {
    checkToken(response);
    return response;
}, error => {
    return Promise.reject(error);
});


/**
 * 封装get请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            ...config
        }).then(response => {
            if (response.status === 200) {
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * 封装get请求
 * @returns {Promise}
 */
export function checkImageLoad(url) {
    return new Promise((resolve) => {
        const img = new Image();

        img.onload = () => {
            resolve(true); // 图片加载成功
        };

        img.onerror = () => {
            resolve(false); // 图片加载失败
        };

        img.src = url; // 设置图片源
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(
            response => {
                if (response.status === 200) {
                    resolve(response.data)
                }
            },
            err => {
                reject(err)
            }
        )
    })
}

export function del(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params: params,
            ...config
        }).then(response => {
            if (response.status === 200) {
                resolve(response.data)
            }
        }).catch(err => {
            reject(err)
        })
    })
}

export function getAxios() {
    return axios
}